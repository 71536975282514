/* button.css */
.highlighted-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}
.highlighted-card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.829);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
  z-index: 1;
}

.highlighted-card:hover::before {
  transform: scaleX(1);
}
.highlighted-card:hover {
  
  background-image: url("/src/img/Logo/YTBrokersLogoGrisClaro.webp");
  opacity: 1;
}
.card-content-fade {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.highlighted-card.show-more .card-content-fade {
  opacity: 0;
}

.feature-list {
  color: rgba(0, 0, 0, 0.692);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(0px);
}

.highlighted-card.show-more .feature-list {
  opacity: 1;
  transform: translateY(0);

}

.show-more-text {
  color: rgba(0, 0, 0, 0.692);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
}

.highlighted-card.show-more .show-more-text {
  opacity: 1;
  transform: translateY(0);
}

.icon {
  font-size: 2.5rem;
  transition: transform 0.3s ease;
  transform: translateY(0);
}

.title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  transition: transform 0.3s ease;
  transform: translateY(0);
}

.highlighted-card.show-more .feature-list {
  transform: translateY(-30px);
}

.highlighted-card.show-more .title {
  transform: translateY(-30px);
}

.highlighted-card.show-more .icon {
  transform: translateY(-10px);
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
  transform: translateY(0);
}

.icon.show-more {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  overflow: hidden;
  transform: translateY(0);
}

.highlighted-card.show-less .icon.show-more {
  transform: translateY(0);
  opacity: 1;
  transform: translateY(0);
}

