@keyframes slideInFromLeft {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }


.image-overlay-container {
    position: relative;
    width: 'fullWidth';
    height: 100vh;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    opacity: 0.9;
  }
  
  .foreground-image {
    animation: slideInFromLeft 2s ease-in;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40%;
    max-height: 40%;
  }
 
  @media screen and (max-width: 900px) {
    
    .foreground-image {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 40%;
        max-height: 40%;
      }
}
  


  