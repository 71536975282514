/* servicios.css */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.servicios {
  opacity: 0; /* Establecemos la opacidad inicial en 0 */
}

.servicios-visible {
  animation: slideInFromRight 3s ease-out;
  opacity: 1; /* Cuando el componente sea visible, restablecemos la opacidad a 1 */
}
/* servicios.css */

