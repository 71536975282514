/* InfoDialog.css */
.info-dialog-container {
    position: relative;
    min-width: 400px;
    
  }
  
  .info-dialog-overlay {
    position: absolute;
    right: 0;
    width: 300px;
    height: 300px;
    background-image: url("/src/img/Logo/YTBrokersLogoSuperiorIzquierda.webp");
    background-size: cover;
    background-position: center;
    opacity: 0.1; 
  }
  